import iconHome from "./assets/home.svg";
import iconHead from "./assets/headphone.svg";
import iconTruck from "./assets/truck.svg";

export const links = [
  {
    name: "Home",
    href: "/home",
    icon: iconHome,
  },
  {
    name: "Atendimento",
    href: "/atendimento",
    icon: iconHead,
    subLinks: [
      {
        name: "Atendimento",
        href: "/atendimento",
      },
      {
        name: "Consulta de vale-troca",
        href: "/atendimento/vale-troca",
      },
    ],
  },
  {
    name: "Slots",
    href: "#",
    icon: iconTruck,
  },
];
