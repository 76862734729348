import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import firebase from "../../firebase";
import { links } from "../../root.helper";
import swal from "@sweetalert/with-react";

import iconLogo from "../../assets/logo.svg";
import iconLogout from "../../assets/logout.svg";
import iconUser from "../../assets/user.svg";
import iconReport from "../../assets/report.svg";

import {
  Container,
  HeaderContainer,
  HeaderStyle,
  Nav,
  Row,
  TitleSystem,
  HeaderActions,
  LogoContainer,
  NavLink,
  LinkButton,
  SubLinksUl,
  SubLinksLi,
  IconHeader,
  CloseModalButton,
} from "./styles";

const Header: React.FC = () => {
  const location = useLocation();
  const [username, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [path, setPath] = useState("");

  const authorizedPersons = [
    "arthur_cardoso_ext@carrefour.com",
    "ana_lucia_ramos_da_silva@carrefour.com",
    "matheus_novais_ext@carrefour.com",
    "rodrigo_zani@carrefour.com",
    "ramon_ramos_ext@carrefour.com",
    "arthur_abreu_ext@carrefour.com",
    "renan_gomes_ext@carrefour.com",
  ];

  async function handleLogout() {
    localStorage.removeItem("group");
    localStorage.removeItem("firebaseui::rememberedAccounts");
    await firebase.auth().signOut();
    window.location.href = "/";
  }

  function showAlert() {
    swal({
      icon: "error",
      text: "Ops, você não tem permissão para esta ação! Procure seu gestor.",
      button: false,
      content: (
        <CloseModalButton onClick={() => swal.close()}>Fechar</CloseModalButton>
      ),
    });
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) window.location.href = "/";
    });

    const user = JSON.parse(
      localStorage.getItem("firebaseui::rememberedAccounts")
    );

    if (user) {
      setUserEmail(user[0].email);
      setUserName(user[0].displayName);
    }
  }, []);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const permission = JSON.parse(localStorage.getItem("access"));
  const getPermissionReport = permission.authorities.find(
    (element: any) => element === "cs-order.refund-partial"
  );

  return (
    <HeaderContainer>
      <HeaderStyle>
        <Container>
          <Row>
            <LogoContainer>
              <img src={iconLogo} alt="logo" height="40px" />
              <TitleSystem>Portal Backoffice</TitleSystem>
            </LogoContainer>
            <HeaderActions>
              <p>
                <IconHeader src={iconUser} alt="user" />
                Olá, {username}!
              </p>
              <button onClick={handleLogout}>
                <IconHeader src={iconLogout} alt="logout" />
                Sair
              </button>
              <button>
                {getPermissionReport ? (
                  <Link to="/atendimento/relatorios">
                    <IconHeader src={iconReport} alt="logout" />
                    Relatórios
                  </Link>
                ) : (
                  <span onClick={showAlert}>
                    <IconHeader src={iconReport} alt="logout" />
                    Relatórios
                  </span>
                )}
              </button>
            </HeaderActions>
          </Row>
        </Container>
      </HeaderStyle>
      <Nav>
        <Container>
          <NavLink>
            {links.map((link) => {
              return (
                <LinkButton
                  key={link.href}
                  active={path.startsWith(link.href) ? true : false}
                  onClick={() => {
                    if (link.name === "Slots") {
                      showAlert();
                    }
                  }}
                >
                  <Link to={link.href}>
                    <img src={link.icon} alt={link.name} /> {link.name}
                  </Link>
                  <SubLinksUl>
                    {link.subLinks
                      ? link.subLinks.map((item) => (
                        <SubLinksLi>
                          <Link to={item.href}>{item.name}</Link>
                        </SubLinksLi>
                      ))
                      : ""}
                  </SubLinksUl>
                </LinkButton>
              );
            })}
            {authorizedPersons.includes(userEmail) && (
              <LinkButton
                active={
                  path.startsWith("atendimento/reembolso-total") ? true : false
                }
              >
                <Link to="/atendimento/reembolso-total">Reembolso total</Link>
              </LinkButton>
            )}
          </NavLink>
        </Container>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
