import styled from "styled-components";

export const HeaderContainer = styled.div``;

export const HeaderStyle = styled.div`
  width: 100%;
  height: 70px;
  background: #fff;
`;

export const Nav = styled.div`
  background: #1e5bc6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  color: #fff;
  width: 100%;
  height: 40px;
`;

export const Container = styled.div`
  max-width: 1112px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
`;

export const IconHeader = styled.img`
  margin-right: 10px;
  margin-top: 2px;
  height: 16px;
  width: 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleSystem = styled.h1`
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #e81e26;
  margin-left: 10px;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  p,
  button {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #78909c;
    height: 25px;
    padding: 0;
    margin-left: 20px;
    background: transparent;
    border: none;
    transition: 0.2 all linear;
    display: flex;
    align-items: center;
  }

  button {
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &:hover {
      color: #1e5bc6;
    }
    a {
      text-decoration: none;
      color: #78909c;
    }
  }
`;

export const NavLink = styled.ul`
  list-style: none;
  color: #fff;
  display: flex;
  position: relative;
  align-items: center;
  margin: 0;
  height: 40px;
  padding: 0;
`;

export const LinkButton = styled.li`
  margin-right: 32px;
  height: 40px;
  align-items: center;
  display: flex;
  border-bottom: 3px solid;
  border-color: ${(props) => (props.active ? "#ffffff" : "transparent")};

  &:hover {
    ul {
      display: inline;
    }
  }

  a {
    color: #fff;
    font-weight: 300;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 4px;
    height: 14px;
  }
`;

export const SubLinksUl = styled.ul`
  position: absolute;
  top: 42px;
  display: none;
  background: #fff;
  padding: 0;
  margin: 0;
  border-top: 5px solid #f7f9fc;
  box-shadow: 0px 0px 8px rgba(176, 190, 197, 0.3);
`;

export const SubLinksLi = styled.li`
  list-style: none;

  &:hover {
    background: #f7f9fc;
  }
  a {
    padding: 12px 20px;
    color: #333;
    font-size: 14px;
  }
`;

export const CloseModalButton = styled.button`
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #1e5bc6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  background: #1e5bc6;
  background-position: left 15px center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: #1f5cc7;
  }
`;
