import styled from "styled-components";

export const Content = styled.div`
  padding-top: 45px;
`;

export const Container = styled.div`
  max-width: 1112px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Card = styled.div`
  width: 350px;
  max-width: 350px;
  box-shadow: 0px 0px 8px rgba(176, 190, 197, 0.3);
  background: #fff;
  padding: 50px;
  margin-right: 30px;
  border-radius: 8px;

  a {
    color: #ffffff;
    text-decoration: none;
    display: block;
    width: max-content;
  }
`;
export const Title = styled.h3`
  font-size: 28px;
  margin-top: 0;
  line-height: 30px;
  font-weight: 300;
  color: #37474f;
`;
export const Button = styled.button`
  font-size: 14px;
  line-height: 14px;
  padding: 15px 20px;
  cursor: pointer;
  border: none;

  display: flex;
  align-items: center;
  text-align: center;

  background: #1e5bc6;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    filter: brightness(0.8);
  }
`;
