import React from "react";
import { Link } from "react-router-dom";
import swal from "@sweetalert/with-react";

import { Content, Container, Card, Title, Button } from "./styles";

const Home: React.FC = () => {
  function showAlert() {
    swal({
      icon: "error",
      text: "Ops, você não tem permissão para esta ação! Procure seu gestor.",
      button: false,
    });
  }

  return (
    <Content>
      <Container>
        <Card>
          <Title>Atendimento</Title>
          <Link to="/atendimento">
            <Button>Acessar</Button>
          </Link>
        </Card>
        <Card>
          <Title>Slots</Title>
          <Link to="#" onClick={() => showAlert()}>
            <Button>Acessar</Button>
          </Link>
        </Card>
      </Container>
    </Content>
  );
};

export default Home;
